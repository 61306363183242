import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import BahaiPic from '../assets/annaR.jpg'
import AbdulBaha from '../assets/abdulbaha1.jpg'
import './CarouselSlider.css'

function CarouselSlider() {
  return (
      <div className='experience'>
          <VerticalTimeline lineColor='#000'>
              <VerticalTimelineElement className='vertical-timeline-element--education'
                  iconStyle={{ background: '#000', color: '#fff' }}
                  date='1912'
              >
                  <h3>Abdu’l-Bahá in America</h3>
                      <img src={AbdulBaha} className='img1' alt='Abdu’l-Bahá in Brooklyn'/>
                  <p className='paragraph'>‘Abdu’l-Bahá, Son of Bahá’u’lláh, the Prophet-Founder of the Bahá’í Faith,
                    arrived in New York on April 11, 1912. His sojourn in North America lasted 239 days,
                     including ten days in Canada, during which He traveled to important cities
                      from the east to the west coast and met with people of divers backgrounds and interests.</p>  
              </VerticalTimelineElement>


              <VerticalTimelineElement className='vertical-timeline-element--education'
                  iconStyle={{ background: '#000', color: '#fff' }}
                  date='1930'
              >
                  <h3>Bahá’í Faith in Texas</h3>
                  
                  <p className='paragraph2'> <img src={BahaiPic} className='img2' alt='Abdu’l-Bahá in Brooklyn'/>The Bahai faith in Texas began in Lakeway by a German-Texan settler named Anna Reinke.
                            Reinke was a seamstress, and the daughter of a German immigrant father and native Texan mother.
                            Reinke discovered the Bahá’í religion after visiting her sister in Washington, D.C.,where she heard the message of Bahá’u’lláh, the founder of the Bahá’í faith.</p>
                  
              </VerticalTimelineElement>

              <VerticalTimelineElement className='vertical-timeline-element--education'
                  iconStyle={{ background: '#000', color: '#fff' }}
                  date='1973-Present'
              >
                  <h3>Bahá’í Faith in San Marcos</h3>
                  <p className='paragraph'> Bahá’ís have been involved in
                    the spiritual education of children, junior youth and youth, sponsoring workshops on
                    racial unity, neighborhood clean-ups,tree planting and gardening projects.
                    They have also actively collaborated with other faith-based traditions and civic organizations to help promote unity and understanding.</p>
                  
              </VerticalTimelineElement>


             
          </VerticalTimeline>
     </div>
  )
}

export default CarouselSlider
