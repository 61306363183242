import React from 'react'
import './AboutStyles.css'
import { Link } from 'react-router-dom'

const About = () => {
  return (
      <div className='about'>
          <div className='card-container'>
              <div className='card'>
                  <h3>The Oneness of God</h3>
                  <span className='bar'></span>                 
                  <p>Fundamental to the Bahá’í Faith is the belief in the oneness of God.
                      Bahá’u’lláh taught the greatest gift of God to humanity
                      in this enlightened age is the awareness of oneness, and
                      that the Founders of the world religions, share a common purpose
                      to unite humanity and ensure the advancement of civilization.</p>
                  <Link to='https://www.bahai.us/beliefs/building-community/the-oneness-of-god/' target="_blank" className='btn'>Read More</Link> 
              </div>
              <div className='card'>
                  <h3>The Oneness of Religion</h3>
                  <span className='bar'></span>                
                  <p>Progressive revelation, a core teaching in the Bahá’i Faith,
                      suggests that religions are inherently one and that truth is
                      revealed by God progressively through a series of divine Messengers.
                      Each Revelation is tailored to suit the needs of the time,
                      the place of their appearance and the capacity of humanity.</p>
                  <Link to='https://www.bahai.org/beliefs/essential-relationships/one-human-family/living-principle-oneness' target="_blank" className='btn'>Read More</Link> 
              </div>
              <div className='card'>
                  <h3>The Oneness of Humanity</h3>
                  <span className='bar'></span>                
                  <p>Bahá’ís hold the conviction that we all belong to one human family
                      -the healthy function must be characterized by harmony, cooperation and reciprocity.
                      “It is not for him to pride himself who loveth his own country, but rather for him who loveth the whole world.” -- Bahá’u’lláh  </p>
                  <Link to='https://www.bahai.org/beliefs/essential-relationships/one-human-family' target="_blank" className='btn'>Read More</Link> 
              </div>
              
          </div>
      
    </div>
  )
}

export default About
