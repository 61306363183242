import React from 'react'
import Navbar from '../components/Navbar'
import Video from '../components/Video'
import Footer from '../components/Footer'

function Home() {
  return (
    <div>
          <Navbar />
      <Video />
      <Footer/>
    </div>
  )
}

export default Home
