
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './routes/Home';
import About from './routes/About';
import Activities from './routes/Activities';
import Contact from './routes/Contact';
import History from './routes/History';
import Resources from './routes/Resources';






function App() {
  
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/activities' element={<Activities />} />
        <Route path='/history' element={<History />} />
        <Route path='/resources' element={<Resources/>}/>
        <Route path='/contact' element={<Contact />} />
        


           </Routes>
    </div>
  );
}

export default App;








