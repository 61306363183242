import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'



import Accord from '../components/Accord'


const References = () => {
  return (
    <div className='Ref'>
      <Navbar />     
  
      <HeroImage text='“Therefore, man must endeavor in all things to investigate the fundamental reality. If he does not 
independently investigate, he has failed to utilize the talent God has bestowed upon him.”
`Abdu’l-Bahá, The Promulgation of Universal Peace'/>      
    
      <Accord />
      
      <Footer/>
    </div>
  )
}

export default References
