import React from 'react'
import './VideoStyles.css'
import {Link} from 'react-router-dom'

import spaceVideo from '../assets/nature3.mp4'

const Video = () => {
  return (
    <div className='hero'>
          <video autoPlay loop muted id='video'>
              <source src={spaceVideo} type='video/mp4' />
          </video>
          <div className='content'>
              <h1>The Baháʼís of San Marcos</h1>
              <p>"The earth is but one country, and mankind its citizens"-Bahá'u'lláh</p>         
          <div>
              <Link to='/about' className='btn'>Who We Are</Link>
              <Link to='/activities' className='btn btn-light'>What We Do</Link>
          </div>
          </div>
          </div>
  )
}

export default Video
