import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HistorySection from '../components/HistorySection'
import HeroImage from '../components/HeroImage'
import CarouselSlider from '../components/CarouselSlider'
// import Slider from '../components/Slider'
// import HistorySlider from '../components/HistorySlider'

const History = () => {
  return (
      <div>
          <Navbar />
          <HeroImage text='I testify before God to the greatness, the inconceivable greatness of this revelation. Again and again have We in most of Our Tablets borne witness to this truth, that mankind may be roused from its heedlessness. – Bahá’u’lláh '/>
          <HistorySection />
      {/* <Slider/> */}
      {/* <HistorySlider/> */}
      <CarouselSlider/>
          <Footer/>
      
    </div>
  )
}

export default History
