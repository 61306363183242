import React from 'react'
import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import Footer from '../components/Footer'
import Form  from '../components/Form'


const Contact = () => {
  return (
    <div>
          <Navbar />
          <HeroImage heading='Get in Touch' text='Have questions? Comments? Want to join activities in your neighborhood? Please feel free to contact us, we’d love to hear from you'/>
          <Form/>
          <Footer/>
    </div>
  )
}

export default Contact
