import React from 'react'
import './ActivityComponent.css'

import Children from '../assets/children.jpg'
import Youth from '../assets/youth.jpg'
import Devotional from '../assets/devotional1.jpg'
import Study from '../assets/study.jpg'



const ActivityComponent = () => {
    return (
        <div className='activities'>
            <div className="activity-container">
                <div className="box">
                    <div className="imgbox">
                    <img src={Children} className='img' alt='youth painting'/>
                    </div>
                    <h1>Children’s Classes</h1>
                    <p>Children’s classes are designed to help the youngest members of our communities
                        develop spiritual qualities and a sense of their own inner potential.
                        In a world where the joy and innocence of childhood can be so easily overwhelmed
                        by the aggressive pursuit of materialistic ends, the moral and spiritual education of children assumes vital importance.</p>
                </div>
                <div className="box">
                    <div className="imgbox">
                    <img src={Youth} className='img' alt='youth painting'/>
                    </div>
                    <h1>Junior Youth Groups</h1>
                    <p>Junior youth groups are designed to empower early teens (ages 12-14) develop their
          capacities, identify needs in their community and work together to create a plan and address those needs.
          As their spiritual powers expand, a new level of awareness
          fosters in them an increased interest in profound questions and in their talents and abilities.</p>
                </div>
                <div className="box">
                    <div className="imgbox">
                    <img src={Devotional} className='img' alt='youth painting'/>
                    </div>
                    <h1>Devotionals</h1>
                    <p>Devotional gatherings where people of all faiths and all paths can come
                        together in a spirit of harmony, worship together and discuss spiritual ideas and concepts.
                        Devotional meetings spring up naturally in a community where a conversation about the spiritual dimension of human existence is growing.
                    </p>
                </div>
                <div className="box">
                    <div className="imgbox">
                    <img src={Study} className='img' alt='youth painting'/>
                    </div>
                    <h1>Study Circles</h1>
                    <p>A study circle is a small group dedicated to the spiritual and moral
empowerment of individuals. The study materials include passages from the
Bahá’í writings related to specific themes and acts of service. Together
participants think about the application of these passages to their individual
and collective lives. Anyone aged fifteen or older, whether a Bahá’í or not, is
welcome to take part.
        </p>
                </div>







            </div>
        </div>
    )
}

export default ActivityComponent
