import React from 'react'
import './FooterStyles.css'
import { FaFacebook,FaTwitter, FaBahai, FaPhone, FaMailBulk, FaSearchLocation, FaDev, FaCopyright, FaYoutube } from 'react-icons/fa'
import {Link} from "react-router-dom"



const Footer = () => {
  return (
      <div className='footer'>
          <div className='footer-container'>
              <div className='left'>
                  <div className='location'>
                      <FaSearchLocation size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />
                  <div>
                      <p>San Marcos</p>
                      <h4>Texas, US</h4>
                  </div>                      
                  </div>
                  <div className='phone'>
                      <h4><FaPhone size={20} style={{ color: '#ffffff', marginRight: '2rem' }}/>512-557-0731</h4>
                  </div>
                  <div className='email'>
                      <h4><FaMailBulk size={20} style={{ color: '#ffffff', marginRight: '2rem' }}/>jeffreyhkester@gmail.com || smtxlsa@gmail.com </h4>
                  </div>
                 
              </div>
              <div className='right'>
                  <h4>-Ye are the fruits of one tree, and the leaves of one branch. ~Bahá’u’lláh</h4>
                  <p>Bahá’ís believe the crucial need facing humanity is to find a unifying vision
                      of the future of society and of the nature and purpose of life. Such a vision unfolds in the writings of Bahá’u’lláh.</p>
                  <div className='social'>
                      <Link to="https://www.bahai.org/" target="_blank">   <FaBahai size={30} style={{ color: '#D4AE37', marginRight: '1rem' }}/></Link>
                      <Link to="https://www.facebook.com/1844L" target="_blank"><FaFacebook size={30} style={{ color: '#ffffff', marginRight: '1rem' }} /></Link>
                   
                      {/* <FaTwitter size={30} style={{ color: '#ffffff', marginRight: '1rem' }} /> */}
                      <FaYoutube size={30} style={{ color: '#ffffff', marginRight: '1rem' }} />
                  </div>
                  <div className='dev'>
                      <h5><FaCopyright size={18} style={{ color: '#ffffff', marginRight: '0.5rem' }}/>2024 Baha'is of San Marcos. All rights reserved</h5>
                      <h6><FaDev size={18} style={{ color: '#ffffff', marginRight: '0.5rem' }} />website developed by: Aaron Enyetu</h6>
                  </div>
              </div>
          </div>
      
    </div>
  )
}

export default Footer
