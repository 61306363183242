import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import AboutCards from '../components/AboutCards'

const About = () => {
  return (
      <div>
          <Navbar />
          <HeroImage heading='We Are One' text='We are Bahá’is. We believe in the teachings of Bahá’u’lláh—that there is one Divine Creator, that all the world’s religions come from this one Source, and that all of humanity is one interconnected family.' />
          <AboutCards/>
          <Footer/>
      
    </div>
  )
}

export default About
