import React from 'react'
import './HistorySectionStyles.css'


const HistorySection = () => {
    return (
      <main>
            
      <div className='pg-header'>
          <div className='container'>
              <h1>History of the Bahá’i Faith in San Marcos</h1>
          </div>      
            </div>
            <div className='container-content'>
                <p>
                Bahá’ís have lived in San Marcos since 1973 and the first Local Spiritual Assembly (elected local
                administrative body) was established that same year.
                Since then, the San Marcos Bahá’í community has been active in promoting the fundamental principles of the oneness of the human race and
                its common spiritual destiny. 
                </p>
                <p>
               
                </p>
            </div>
           

      </main>
      
  )
}

export default HistorySection
