import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './FormStyles.css'

const Form = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
 



  const contact = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    emailjs.sendForm('service_6ypwflj', 'template_8edn9ul', contact.current, 'I6kBInseHIEcZAP2t')
      .then((result) => {
        console.log(result.text)
        alert("Your message sent successfully!")
        
      }, (error) => {
        console.log(error.text)
      })
    
    setName('')
    setEmail('')
    setMessage('')
  }
  return (
      <div className='form'>
          <form ref={contact} onSubmit={sendEmail}>
              <label>Your Name</label>
        <input type='text' value={name} name="from_name" onChange={(e) => setName(e.target.value)}></input>
              <label>Email</label>
              <input type='email' value={email} name="from_email" onChange={(e) => setEmail(e.target.value)}></input>
              {/* <label>Subject</label>
              <input type='text'></input> */}
              <label>Message</label>
              <textarea rows='6' placeholder='Please type a short message here' name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
              <button className='btn' type="submit" value="Send">Submit</button>
          </form>
      
    </div>
  )
}

export default Form
