import React, {useState} from 'react'

import { NavLink, Link } from 'react-router-dom'

import './NavbarStyles.css'

import { FaBars, FaTimes } from 'react-icons/fa'

import logo from '../assets/logo.png'




const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

  return (
      <div className='header'>
          <Link to='/'>            
          <img className='logo' src={logo} alt={"logo"}/> 
          </Link>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li>
                  <NavLink to='/'>Home</NavLink>
              </li>
              <li>
                  <NavLink to='/about'>About</NavLink>
              </li>
              <li>
                  <NavLink to='/activities'>Activities</NavLink>
              </li>
              <li>
                  <NavLink to='/history'>History</NavLink>
              </li>
              <li>
                  <NavLink to='/resources'>Resources</NavLink>
              </li>
              <li>
                  <NavLink to='/contact'>Contact</NavLink>
              </li>
          </ul>

          <div className='hamburger' onClick={handleClick}>
              {click ? (<FaTimes size={20} style={{color: '#fff'}}/>) : ( <FaBars size={20} style={{color: '#fff'}} />)}
             
          </div>

      
    </div>
  )
}

export default Navbar
