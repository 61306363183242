import React from 'react'
import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import Footer from '../components/Footer'
// import ActivitiesComponent from '../components/ActivitiesComponent'
import ActivityComponent from '../components/ActivityComponent'

const Activities = () => {
  return (
    <div>
          <Navbar />
          <HeroImage heading='Building Community' text='Bahá’is all over the world are working in neighborhoods to support local residents in their efforts to take charge of their own social and spiritual development. All of these activities are open to anyone.' />
          <ActivityComponent/>
          <Footer/>
    </div>
  )
}

export default Activities
