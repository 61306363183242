import { useRef, useState } from "react";
import "./Accord.css";
import { gsap } from "gsap";
import { Link } from "react-router-dom";



function Accord() {
  const [openAccordion, setOpenAccordion] = useState(null);
  const accordionRefs = useRef([]);

  const handleAccordionClick = (index) => {
    if (index === openAccordion) {
      gsap.to(
        accordionRefs.current[index].querySelector(".accordion__details"),
        {
          height: 0,
          duration: 1,
          ease: "power1.inOut",
          onComplete: () => setOpenAccordion(null),
        }
      );
      console.log(openAccordion);
    } else {
      if (openAccordion !== null) {
        gsap.to(
          accordionRefs.current[openAccordion].querySelector(
            ".accordion__details"
          ),
          {
            height: 0,
            duration: 1,
            ease: "power1.inOut",
          }
        );
      }
      setOpenAccordion(index);
      gsap.fromTo(
        accordionRefs.current[index].querySelector(".accordion__details"),
        { height: 0 },
        {
          height: "auto",
          duration: 1,
          ease: "power1.inOut",
        }
      );
    }
  };
  // TODO - MID

  return (
    <div className="App">
      <div className="accordion__container">
        <div
          className={`accordion__item  ${openAccordion === 0 ? "open" : ""}`}
          ref={(el) => (accordionRefs.current[0] = el)}
        >
          <div
            className="accordion__header"
            onClick={() => handleAccordionClick(0)}
          >
            <p className="accordion__number">01</p>
            <p className="accordion__name">Reference Websites</p>
          </div>

          <div className="accordion__details">
            <ul>
                  <li>
                    <Link to="https://www.bahai.org/" target="_blank"> Worldwide Bahá’í Website</Link>                     
                  </li>
                  <li>
                    <Link to="https://www.bahai.us/" target="_blank"> U.S. Bahá’í Website</Link>             
                  </li>
                   <li>
                  <Link to="https://www.austinbahai.org/ " target="_blank"> Austin Bahá’í Website</Link>         
                  </li>
            </ul>
          </div>
        </div>

        <div
          className={`accordion__item ${openAccordion === 1 ? "open" : ""}`}
          ref={(el) => (accordionRefs.current[1] = el)}
        >
          <div
            className="accordion__header"
            onClick={() => handleAccordionClick(1)}
          >
            <p className="accordion__number">02</p>
            <p className="accordion__name">
              Reference for Activities
            </p>
          </div>
          <div className="accordion__details">
            <ul>
                          <li>
                          <Link to="https://www.bahai.org/action/devotional-life/devotional-meetings" target="_blank"> Devotional Meetings</Link>
             
              </li>
                          <li>
                          <Link to="https://www.bahaiteachings.org/childrens-classes-place-foster-learning-communication-unity/" target="_blank"> Children’s Classes</Link>
             

              </li>
                          <li>
                          <Link to="https://www.bahaiteachings.org/what-is-junior-youth-spiritual-empowerment-program/" target="_blank"> Junior Youth Groups</Link>
             
             
                          </li>
                          <li>
                          <Link to="https://www.bahai.org/action/response-call-bahaullah/training-institute" target="_blank"> Training Institute Study Circles</Link>
                     

                          </li>
            </ul>
          </div>
        </div>
        <div
          className={`accordion__item ${openAccordion === 2 ? "open" : ""}`}
          ref={(el) => (accordionRefs.current[2] = el)}
        >
          <div
            className="accordion__header"
            onClick={() => handleAccordionClick(2)}
          >
            <p className="accordion__number">03</p>
            <p className="accordion__name">Community Contacts</p>
          </div>
          <div className="accordion__details">
            <ul>
              <li>
              Austin: (512) 926-8880 

              </li>
              <li>
              Kyle: (Golriz) golriz.sedaghat@gmail.com (310)994-5488

              </li>
              <li>
              Martindale: (Carolyn) akalydia@gmail.com (512) 644-3558

                          </li>
                          <li>
                          Buda: (Aniela) aniela999@gmail.com
                          </li>
                          <li>
                          Wimberley: (Tom) tomtopski@gmail.com (361) 541-9310
                          </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accord;